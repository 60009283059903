import React, { useState, useEffect, useCallback } from "react";
import {
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Button, MenuItem, Select
} from "@mui/material";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import { format } from "date-fns";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectTracker from "./ProjectTracker";
import "./showprojects.css";
import { ResizableBox } from "react-resizable";
import { Link } from "react-router-dom";
import "react-resizable/css/styles.css";
import socket from '../../socket'
import Spinner from "../../component/Spinner/Spinner";


const COLUMN_WIDTHS_KEY = "tableColumnWidths";
const getStoredColumnWidths = () => JSON.parse(localStorage.getItem(COLUMN_WIDTHS_KEY)) || [200, 200, 200];
const storeColumnWidths = (widths) => localStorage.setItem(COLUMN_WIDTHS_KEY, JSON.stringify(widths));

const ResizableTableCell = ({ width, onResize, children }) => (
  <ResizableBox
    width={width}
    height={0}
    axis="x"
    resizeHandles={["e"]}
    onResize={onResize}
    style={{ display: "flex", alignItems: "center", minWidth: '120px', minHeight: '10px', color: 'white' }}
  >
    <div style={{ width: "100%" }}>{children}</div>
  </ResizableBox>
);

const ProjectTable = () => {
  const [columnWidths, setColumnWidths] = useState(getStoredColumnWidths())
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const getInitialLimit = () => {
    const savedLimit = localStorage.getItem("itemsPerPage");
    return savedLimit ? Number(savedLimit) : 10; // Default to 10 if not found
  };

  const [limit, setLimit] = useState(getInitialLimit());
  const [filters, setFilters] = useState({
    consultant: "",
    contractor: "",
    projectName: "",
    location: "",
    userName: "",
    projectStatus: "",
  });
  const [user, setUser] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [allowedColumns, setAllowedColumns] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupColumn, setShowPopupColumn] = useState(false);
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showImportExportPopup, setShowImportExportPopup] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null, // the column name
    direction: "asc", // asc or desc
  });

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
  };

  // Sorting logic
  const sortedRows = [...filteredRows].sort((a, b) => {
    if (sortConfig.key) {
      let aVal = a[sortConfig.key];
      let bVal = b[sortConfig.key];

      // Handling dates, numbers, and strings separately
      if (sortConfig.key.includes("Date")) {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      } else if (!isNaN(aVal) && !isNaN(bVal)) {
        aVal = parseFloat(aVal);
        bVal = parseFloat(bVal);
      }

      if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Handle resize and store in state and localStorage
  const handleResize = (index) => (e) => {
    e.preventDefault();
    const startX = e.clientX;

    const onMouseMove = (moveEvent) => {
      const newWidth = Math.max(columnWidths[index] + (moveEvent.clientX - startX), 50); // Prevent negative width
      const newWidths = [...columnWidths];
      newWidths[index] = newWidth;
      setColumnWidths(newWidths);
    };

    const onMouseUp = () => {
      storeColumnWidths(columnWidths); // Update localStorage on mouse up
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const getInitialVisibleColumns = () => {
    const savedColumns = localStorage.getItem('visibleColumns');
    return savedColumns
      ? JSON.parse(savedColumns)
      : {
        UserName: true,
        ProjectName: true,
        Location: true,
        Consultant: true,
        Contractor: true,
        ProjectType: true,
        QuotedValue: true,
        QuotedDate: true,
        FollowupDate: true,
        ProjectStatus: true,
        MakeList: true,
        Person: true,
        Amount: true,
        Status: true,
        Comment: true,
      };
  };

  const [visibleColumns, setVisibleColumns] = useState(getInitialVisibleColumns);

  // Log the current and previous state (for debugging)
  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => {

      const updatedColumns = { ...prev, [column]: !prev[column] }; // Toggle column visibility
      return updatedColumns;
    });
  };

  // Persist the updated column visibility in localStorage
  useEffect(() => {
    localStorage.setItem('visibleColumns', JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  // Define column names with friendly display labels
  const columnNames = [
    { key: "UserName", label: "User Name" },
    { key: "ProjectName", label: "ProjectName" },
    { key: "Location", label: "Location" },
    { key: "Consultant", label: "Consultant" },
    { key: "Contractor", label: "Contractor" },
    { key: "ProjectType", label: "Project Type" },
    { key: "QuotedValue", label: "Quoted Value" },
    { key: "QuotedDate", label: "Quoted Date" },
    { key: "FollowupDate", label: "Followup Date" },
    { key: "ProjectStatus", label: "Project Status" },
    { key: "MakeList", label: "Make List" },
    { key: "Person", label: "Person" },
    { key: "Amount", label: "Amount" },
    { key: "Status", label: "Status" },
    { key: "Comment", label: "Comment" },
  ];

  const userRole = user.Role;

  const userId = user._id;

  const applyFilters = useCallback(
    (project) => {
      const { consultant, contractor, projectName, location, userName, projectStatus } = filters;
      const consultantLower = consultant?.toLowerCase() || "";
      const contractorLower = contractor?.toLowerCase() || "";
      const projectNameLower = projectName?.toLowerCase() || "";
      const locationLower = location?.toLowerCase() || "";
      const userNameLower = userName?.toLowerCase() || "";
      const projectStatusLower = projectStatus?.toLowerCase() || "";
      const matchesFilters =
        (consultantLower === "" ||
          project.Consultant?.toLowerCase().includes(consultantLower)) &&
        (contractorLower === "" ||
          project.Contractor?.toLowerCase().startsWith(contractorLower)) &&
        (projectNameLower === "" ||
          project.ProjectName?.toLowerCase().startsWith(projectNameLower)) &&
        (locationLower === "" ||
          project.Location?.toLowerCase().startsWith(locationLower)) &&
        (userNameLower === "" ||
          project.UserName?.toLowerCase().includes(userNameLower)) &&
        (projectStatusLower === "" ||
          project.ProjectStatus?.toLowerCase().startsWith(projectStatusLower));

      const canSeeProject = user?.PermissiontoSee?.includes(project.CreatedBy);

      return matchesFilters && canSeeProject;
    },
    [filters, user]
  );

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}projectusers`
      );
      const currentUser = response.data.allUsers.find(
        (u) => u._id === getUserIdFromUrl()
      );
      setUser(currentUser);
      setAllowedColumns(currentUser.AllowedColumns || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);


  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        ...filters,
        page: filtersApplied() ? 1 : currentPage, // Reset to page 1 if filters are applied
        limit,
        userId: getUserIdFromUrl(),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}show-projects`,
        {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setRows(response.data.projects);
      setFilteredRows(response.data.projects);
      setTotalPages(response.data.totalPages);
      if (filtersApplied()) setCurrentPage(1); // Reset the current page
    } catch (error) {
      toast.error("Error fetching data");
      console.error("Error fetching data:", error.response?.data || error);
    } finally {
      setIsLoading(false);
    }
  }, [filters, currentPage, limit]);

  const filtersApplied = () => {
    return Object.values(filters).some((value) => value.trim() !== "");
  };


  useEffect(() => {
    fetchData();

    // Listen for the `update_data` event
    socket.on('update_data', (newProjects) => {
      fetchData(); // Refresh the data
    });

    // Cleanup listener on unmount
    return () => {
      socket.off('update_data');
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle limit change
  const handleLimitChange = (event) => {
    const newLimit = Number(event.target.value);
    setLimit(newLimit); // Update the limit state
    localStorage.setItem("itemsPerPage", newLimit); // Store the new limit in localStorage
    setCurrentPage(1); // Reset to the first page whenever the limit changes
  };



  const getUserIdFromUrl = () => {
    const url = window.location.href;
    return url.substring(url.lastIndexOf("/") + 1);
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [page, filters, user, fetchData]);

  useEffect(() => {
    if (Array.isArray(rows)) {
      setFilteredRows(rows.filter(applyFilters));
    }
  }, [rows, filters, applyFilters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setPage(1);
  };
  const handleDelete = async (id) => {
    try {
      // Show a confirmation dialog
      const isConfirmed = window.confirm("Do you really want to delete this project?");
      if (!isConfirmed) {
        return; // Exit if the user cancels
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}delete-project/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setRows((prevRows) => prevRows.filter((row) => row._id !== id));
      toast.success("Project deleted successfully");
    } catch (error) {
      toast.error("Error deleting project");
    }
  };

  const handleEditClick = (id) => {
    setEditRowId(id);
    const rowToEdit = rows.find((row) => row._id === id);

    const formatDateForInput = (date) => {
      if (!date) return ""; // Handle null or undefined dates
      const parsedDate = new Date(date); // Parse the date string to a Date object
      return parsedDate.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
    };

    setEditableFields({
      QuotedValue: rowToEdit.QuotedValue,
      ProjectStatus: rowToEdit.ProjectStatus,
      Comment: rowToEdit.Comment,
      ProjectName: rowToEdit.ProjectName,
      Location: rowToEdit.Location,
      Consultant: rowToEdit.Consultant,
      Contractor: rowToEdit.Contractor,
      ProjectType: rowToEdit.ProjectType,
      MakeList: rowToEdit.MakeList,
      Specifications: rowToEdit.Specifications, // Ensure this is included
      QuotedDate: formatDateForInput(rowToEdit.QuotedDate),
      FollowupDate: formatDateForInput(rowToEdit.FollowupDate),
      Person: rowToEdit.Person,
      Amount: rowToEdit.Amount,
      Status: rowToEdit.Status,
      createdAt: new Date().toISOString(),
    });

  };

  const handleFieldChange = (event, fieldName) => {
    const { value } = event.target;

    // Check if the field being edited is Specifications
    if (fieldName === "Specifications") {
      // Convert the string input into an array based on commas
      const specificationsArray = value.split(',').map(spec => spec.trim());

      // Update the editable fields state
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: specificationsArray, // Update with the array
      }));
    } else {
      // For other fields, simply update the value
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: value,
      }));
    }
  };


  const handleSave = async () => {
    if (!editRowId) return;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}update-project/${editRowId}`,
        editableFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const updatedProject = response.data.updatedProject;

      // Update local state
      setRows((prevRows) =>
        prevRows.map((row) =>
          row._id === editRowId ? { ...row, ...updatedProject } : row
        )
      );
      setFilteredRows((prevFilteredRows) =>
        prevFilteredRows.map((row) =>
          row._id === editRowId ? { ...row, ...updatedProject } : row
        )
      );

      // Emit an event to notify other clients
      socket.emit('projectUpdated', updatedProject);

      // Optionally fetch data again to ensure everything is in sync
      fetchData(); // Fetch updated data from the server

      setEditRowId(null);
      setEditableFields({});
      toast.success("Project updated successfully");
    } catch (error) {
      toast.error("Error updating project");
      console.error("Error updating project:", error);
    }
  };

  // Set up a listener for project updates
  useEffect(() => {
    const handleProjectUpdate = (updatedProject) => {

      setRows((prevRows) =>
        prevRows.map((row) => (row._id === updatedProject._id ? { ...row, ...updatedProject } : row))
      );
      setFilteredRows((prevFilteredRows) =>
        prevFilteredRows.map((row) => (row._id === updatedProject._id ? { ...row, ...updatedProject } : row))
      );
    };

    socket.on('projectUpdated', handleProjectUpdate);
    return () => {
      socket.off('projectUpdated', handleProjectUpdate);
    };
  }, []);

  useEffect(() => {
    fetchData(); // Fetch data when component mounts
  }, [fetchData]);


  const handleCancel = () => {
    setEditRowId(null);
    setEditableFields({});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy");
  };

  const exportToExcel = () => {
    const data = filteredRows.map((row) => ({
      "User Name": row.UserName,
      "Project Name": row.ProjectName,
      Location: row.Location,
      Consultant: row.Consultant,
      Contractor: row.Contractor,
      "Project Type": row.ProjectType,
      "Quoted Value": row.QuotedValue,
      "Quoted Date": row.QuotedDate ? formatDate(row.QuotedDate) : "",
      "Followup Date": row.FollowupDate ? formatDate(row.FollowupDate) : "",
      "Project Status": row.ProjectStatus,
      "Make List": row.MakeList,
      Specifications: row.Specifications,
      Person: row.Person,
      Amount: row.Amount,
      Status: row.Status,
      Comment: row.Comment,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects");
    XLSX.writeFile(wb, "Projects.xlsx");
  };

  const importFromExcel = () => {
    if (!selectedFile) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userId", getUserIdFromUrl());

    axios
      .post(`${process.env.REACT_APP_API_URL}import-projects`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data.projects)) {
          const newProjects = response.data.projects;
          setRows((prevRows) => [...prevRows, ...newProjects]);
          setFilteredRows((prevFilteredRows) => [
            ...prevFilteredRows,
            ...newProjects.filter(applyFilters),
          ]);
          setSelectedFile(null); // Clear the selected file
          toast.success("File imported successfully");
        } else {
          toast.error("Error importing file");
        }
      })
      .catch((error) => {
        console.error("Error importing file:", error);
        toast.error("Error importing file");
      });
  };

  const resetFilters = () => {
    setFilters({
      consultant: "",
      contractor: "",
      projectName: "",
      location: "",
      userName: "",
      projectStatus: "",
    });
    setPage(1);
    fetchData(); // Call fetchData to refresh the data based on reset filters
  };


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleAddEmployee = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClosePopupcolumn = () => {
    setShowPopupColumn(false);
  };
  const handleShowPopupcolumn = () => {
    setShowPopupColumn(true);
  };

  const handleShowImportExportPopup = () => {
    setShowImportExportPopup(true); // Show the Import/Export popup
  };

  const handleCloseImportExportPopup = () => {
    setShowImportExportPopup(false); // Close the Import/Export popup
  };

  const handleBulkDelete = async () => {
    try {
      // Show a confirmation dialog for bulk delete
      const isConfirmed = window.confirm("Do you really want to delete the selected projects?");
      if (!isConfirmed) {
        return; // Exit if the user cancels
      }

      await Promise.all(
        bulkSelectedIds.map((id) =>
          axios.delete(`${process.env.REACT_APP_API_URL}delete-project/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
        )
      );

      // Remove the deleted projects from the rows and reset selected IDs
      setRows((prev) => prev.filter((project) => !bulkSelectedIds.includes(project._id)));
      setBulkSelectedIds([]); // Reset selection after deletion

      toast.success("Selected projects deleted successfully");
    } catch (error) {
      console.error("Error deleting selected projects:", error);
      toast.error("Error deleting selected projects");
    }
  };

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleArchiveSelected = async () => {
    try {
   
      await Promise.all(bulkSelectedIds.map((id) => axios.post(`${API_BASE_URL}archive-project/${id}`, { userId })));
      setRows((prev) => prev.filter((project) => !bulkSelectedIds.includes(project._id)));
      setBulkSelectedIds([]); // Reset selection after archiving
      toast.success("Selected projects Archived successfully");
    } catch (err) {
      console.error('Error archiving selected projects:', err);
    }
  };
 


  return (
    <div className="show-project-wrap">
      <div className="top-buttons">
        <div className="add-project-section">
          <div className="add-project-btn" style={{ cursor: "pointer" }} onClick={() => handleAddEmployee()} >
            <span> + Add Project </span>
          </div>
          {showPopup && (
            <div className="popup-overlay">
              <div className="add-project-popup">
                <ProjectTracker onClose={handleClosePopup} />
              </div>
            </div>
          )}
        </div>
        <div className="import-export-box">


          <Button onClick={() => setIsOpen(prev => !prev)}>
            {isOpen ? 'Hide Filters' : 'Show Filters'}
          </Button>
          <Button><Link to='/archive-projects'>Show Archived</Link></Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleArchiveSelected}
            disabled={bulkSelectedIds.length === 0}
          >
            Archive Selected
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
            disabled={bulkSelectedIds.length === 0}
          >
            Delete Selected
          </Button>

          {showImportExportPopup && (
            <div className="popup-overlay">
              <div className="import-export-filter-popup">
                <div className="popup-header">
                  <h3>Import/Export</h3>
                  <button onClick={handleCloseImportExportPopup} className="close-button">X</button>
                </div>
                <div className="popup-content">
                  {userRole === "administrator" && (
                    <div div className="import-export-section">
                      <div>
                        <Button onClick={exportToExcel}>Export to Excel</Button>
                      </div>
                      <div>
                        <input type="file" accept=".xlsx" onChange={handleFileChange} />
                        <Button onClick={importFromExcel}>Import from Excel</Button>
                      </div>
                    </div>

                  )}

                </div>
              </div>
            </div>
          )}

        </div>
      </div >
      <>
        <div className="project-table">
          <div className="filter-row">
            {showPopupColumn && (
              <div className="popup-overlay">
                <div className="column-filter comment-popup">
                  {columnNames.map((column) => (
                    <label key={column.key}>
                      <input
                        type="checkbox"
                        checked={visibleColumns[column.key]}
                        onChange={() => toggleColumnVisibility(column.key)}
                      />
                      {column.label}
                    </label>
                  ))}
                  <button onClick={handleClosePopupcolumn}>Close</button>
                </div>
              </div>
            )}

          </div>

          <div className="input-filters">
            {isOpen && (
              <div className="filters-content open">
                <input
                  type="text"
                  placeholder="User Name"
                  name="userName"
                  value={filters.userName}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  placeholder="Project Name"
                  name="projectName"
                  value={filters.projectName}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  placeholder="Consultant"
                  name="consultant"
                  value={filters.consultant}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  placeholder="Contractor"
                  name="contractor"
                  value={filters.contractor}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  placeholder="Location"
                  name="location"
                  value={filters.location}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  placeholder="Project Status"
                  name="projectStatus"
                  value={filters.projectStatus}
                  onChange={handleFilterChange}
                />
                <button onClick={resetFilters}>Reset</button>
                <button onClick={handleShowImportExportPopup}>Import / Export</button>
                <button onClick={handleShowPopupcolumn}>Column Filters</button>
              </div>
            )}
          </div>
        </div>


        <TableContainer className="project-grid" component={Paper}>
          <div className="project-range-box">
            <label htmlFor="limit">Items per page:</label>
            <select id="limit" value={limit} onChange={handleLimitChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </select>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="check-box" style={{ width: columnWidths[0], border: '1px solid black', fontWeight: '600' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setBulkSelectedIds(checked ? rows.map((row) => row._id) : []);
                    }}
                  />
                </TableCell>
                {allowedColumns.includes("UserName") && (
                  <>
                    {visibleColumns.UserName && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("UserName")}
                      >
                        <ResizableTableCell width={columnWidths[0]} onResize={(e, data) => handleResize(0, e, data)} >
                          User Name {sortConfig.key === "UserName" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("ProjectName") && (
                  <>
                    {visibleColumns.ProjectName && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("ProjectName")}
                      >
                        <ResizableTableCell width={columnWidths[1]} onResize={(e, data) => handleResize(0, e, data)} >

                          Project Name {sortConfig.key === "ProjectName" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Location") && (
                  <>
                    {visibleColumns.Location && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Location")}
                      >
                        <ResizableTableCell width={columnWidths[2]} onResize={(e, data) => handleResize(0, e, data)} >

                          Location {sortConfig.key === "Location" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Consultant") && (
                  <>
                    {visibleColumns.Consultant && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Consultant")}
                      >
                        <ResizableTableCell width={columnWidths[3]} onResize={(e, data) => handleResize(0, e, data)} >

                          Consultant {sortConfig.key === "Consultant" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Contractor") && (
                  <>
                    {visibleColumns.Contractor && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Contractor")}
                      >
                        <ResizableTableCell width={columnWidths[4]} onResize={(e, data) => handleResize(0, e, data)} >

                          Contractor {sortConfig.key === "Contractor" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("ProjectType") && (
                  <>
                    {visibleColumns.ProjectType && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("ProjectType")}
                      >
                        <ResizableTableCell width={columnWidths[5]} onResize={(e, data) => handleResize(0, e, data)} >

                          Project Type {sortConfig.key === "ProjectType" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("QuotedValue") && (
                  <>
                    {visibleColumns.QuotedValue && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("QuotedValue")}
                      >
                        <ResizableTableCell width={columnWidths[6]} onResize={(e, data) => handleResize(0, e, data)} >
                          Quoted Value {sortConfig.key === "QuotedValue" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("QuotedDate") && (
                  <>
                    {visibleColumns.QuotedDate && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("QuotedDate")}
                      >
                        <ResizableTableCell width={columnWidths[7]} onResize={(e, data) => handleResize(0, e, data)} >

                          Quoted Date {sortConfig.key === "QuotedDate" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("FollowupDate") && (
                  <>
                    {visibleColumns.FollowupDate && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("FollowupDate")}
                      >
                        <ResizableTableCell width={columnWidths[8]} onResize={(e, data) => handleResize(0, e, data)} >

                          Followup Date {sortConfig.key === "FollowupDate" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("ProjectStatus") && (
                  <>
                    {visibleColumns.ProjectStatus && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("ProjectStatus")}
                      >
                        <ResizableTableCell width={columnWidths[9]} onResize={(e, data) => handleResize(0, e, data)} >

                          Project Status {sortConfig.key === "ProjectStatus" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("MakeList") && (
                  <>
                    {visibleColumns.MakeList && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("MakeList")}
                      >
                        <ResizableTableCell width={columnWidths[10]} onResize={(e, data) => handleResize(0, e, data)} >

                          Make List {sortConfig.key === "MakeList" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Person") && (
                  <>
                    {visibleColumns.Person && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Person")}
                      >
                        <ResizableTableCell width={columnWidths[11]} onResize={(e, data) => handleResize(0, e, data)} >

                          Person {sortConfig.key === "Person" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Amount") && (
                  <>
                    {visibleColumns.Amount && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Amount")}
                      >
                        <ResizableTableCell width={columnWidths[12]} onResize={(e, data) => handleResize(0, e, data)} >

                          Amount {sortConfig.key === "Amount" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Status") && (
                  <>
                    {visibleColumns.Status && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Status")}
                      >
                        <ResizableTableCell width={columnWidths[13]} onResize={(e, data) => handleResize(0, e, data)} >

                          Status {sortConfig.key === "Status" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                {allowedColumns.includes("Comment") && (
                  <>
                    {visibleColumns.Comment && (
                      <TableCell className="table-cell"
                        style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => handleSort("Comment")}
                      >
                        <ResizableTableCell width={columnWidths[14]} onResize={(e, data) => handleResize(0, e, data)} >

                          Comment {sortConfig.key === "Comment" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                        </ResizableTableCell>
                      </TableCell>
                    )}
                  </>
                )}
                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600" }}>Actions</TableCell>
              </TableRow>
            </TableHead>

            {isLoading && <Spinner />}
            <TableBody style={{ border: "1px solid black" }}>
              {sortedRows.map((row) => (
                <TableRow key={row._id} style={{ border: "1px solid black" }} >
                  <TableCell className="check-box" style={{ width: columnWidths[0], border: '1px solid black', fontWeight: '600' }}>

                    <input
                      type="checkbox"
                      checked={bulkSelectedIds.includes(row._id)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setBulkSelectedIds((prev) =>
                          checked ? [...prev, row._id] : prev.filter((id) => id !== row._id)
                        );
                      }}
                    />


                  </TableCell>
                  {allowedColumns.includes("UserName") && (
                    <>
                      {visibleColumns.UserName && (
                        <TableCell className="table-cell" style={{ fontSize: "15px", textTransform:'capitalize'}}>
                          <strong>{row.UserName}</strong>
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("ProjectName") && (
                    <>
                      {visibleColumns.ProjectName && (
                        <TableCell className="table-cell" >
                          {editRowId === row._id ? (
                            <TextField className="table-cell"
                              name="ProjectName"
                              value={
                                editableFields.ProjectName !== undefined
                                  ? editableFields.ProjectName
                                  : row.ProjectName
                              }
                              onChange={(e) => handleFieldChange(e, "ProjectName")}
                            />
                          ) : (
                            row.ProjectName
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("Location") && (
                    <>
                      {visibleColumns.Location && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField className="table-cell"
                              name="Location"
                              value={
                                editableFields.Location !== undefined
                                  ? editableFields.Location
                                  : row.Location
                              }
                              onChange={(e) => handleFieldChange(e, "Location")}
                            />
                          ) : (
                            row.Location
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("Consultant") && (
                    <>
                      {visibleColumns.Consultant && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField className="table-cell"
                              name="Consultant"
                              value={
                                editableFields.Consultant !== undefined
                                  ? editableFields.Consultant
                                  : row.Consultant
                              }
                              onChange={(e) => handleFieldChange(e, "Consultant")}
                            />
                          ) : (
                            row.Consultant
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("Contractor") && (
                    <>
                      {visibleColumns.Contractor && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField className="table-cell"
                              name="Contractor"
                              value={
                                editableFields.Contractor !== undefined
                                  ? editableFields.Contractor
                                  : row.Contractor
                              }
                              onChange={(e) => handleFieldChange(e, "Contractor")}
                            />
                          ) : (
                            row.Contractor
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("ProjectType") && (
                    <>
                      {visibleColumns.ProjectType && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField className="table-cell"
                              name="ProjectType"
                              value={
                                editableFields.ProjectType !== undefined
                                  ? editableFields.ProjectType
                                  : row.ProjectType
                              }
                              onChange={(e) => handleFieldChange(e, "ProjectType")}
                            />
                          ) : (
                            row.ProjectType
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("QuotedValue") && (
                    <>
                      {visibleColumns.QuotedValue && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField className="table-cell"
                              name="QuotedValue"
                              value={
                                editableFields.QuotedValue !== undefined
                                  ? editableFields.QuotedValue
                                  : row.QuotedValue
                              }
                              onChange={(e) => handleFieldChange(e, "QuotedValue")}
                            />
                          ) : (
                            row.QuotedValue
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("QuotedDate") && (
                    <>
                      {visibleColumns.QuotedDate && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField
                              className="table-cell"
                              name="QuotedDate"
                              type="date"
                              value={
                                editableFields.QuotedDate !== undefined
                                  ? editableFields.QuotedDate // Use value from editableFields
                                  : row.QuotedDate
                                    ? row.QuotedDate.split("T")[0] // Use row value if editableFields is undefined
                                    : ""
                              }
                              onChange={(e) => handleFieldChange(e, "QuotedDate")}
                            />
                          ) : row.QuotedDate ? (
                            formatDate(row.QuotedDate) // Display formatted date
                          ) : (
                            "dd-mm-yyyy" // Placeholder for empty date
                          )}
                        </TableCell>
                      )}
                    </>
                  )}

                  {allowedColumns.includes("FollowupDate") && (
                    <>
                      {visibleColumns.FollowupDate && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField
                              className="table-cell"
                              name="FollowupDate"
                              type="date"
                              value={
                                editableFields.FollowupDate !== undefined
                                  ? editableFields.FollowupDate // Use value from editableFields
                                  : row.FollowupDate
                                    ? row.FollowupDate.split("T")[0] // Use row value if editableFields is undefined
                                    : ""
                              }
                              onChange={(e) => handleFieldChange(e, "FollowupDate")}
                            />
                          ) : row.FollowupDate ? (
                            formatDate(row.FollowupDate) // Display formatted date
                          ) : (
                            "dd-mm-yyyy" // Placeholder for empty date
                          )}
                        </TableCell>
                      )}
                    </>
                  )}


                  {allowedColumns.includes("ProjectStatus") && (
                    <>
                      {visibleColumns.ProjectStatus && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <Select
                              className="select-field table-cell"
                              name="ProjectStatus"
                              value={
                                editableFields.ProjectStatus !== undefined ? editableFields.ProjectStatus : row.ProjectStatus
                              }
                              onChange={(e) =>
                                handleFieldChange(e, "ProjectStatus")
                              }
                            >
                              <MenuItem value="" disabled>
                                Select Status
                              </MenuItem>
                              <MenuItem value="Bidding">Bidding</MenuItem>
                              <MenuItem value="Contractor-Won">Contractor Won</MenuItem>
                              <MenuItem value="Contractor Lost">Contractor Lost</MenuItem>
                              <MenuItem value="Omicron Los">Omicron Lost</MenuItem>
                              <MenuItem value="Omicron Won">Omicron Won</MenuItem>
                              <MenuItem value="Part PO Reciv">Part PO Recive</MenuItem>
                              <MenuItem value="Complete PO Recive">Complete PO Recive</MenuItem>
                            </Select>
                          ) : (
                            row.ProjectStatus
                          )}
                        </TableCell>
                      )}
                    </>
                  )}

                  {allowedColumns.includes("MakeList") && (
                    <>
                      {visibleColumns.MakeList && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <>
                              <Select
                                className="table-cell"
                                name="MakeList"
                                value={editableFields.MakeList !== undefined ? editableFields.MakeList : row.MakeList}
                                onChange={(e) => handleFieldChange(e, "MakeList")}
                              >
                                <MenuItem value="" disabled>
                                  Select Status
                                </MenuItem>
                                <MenuItem value="Make Approved">Make Approved</MenuItem>
                                <MenuItem value="Make Approved With Spec">Make Approved With Spec</MenuItem>
                                <MenuItem value="Not Approved">Not Approved</MenuItem>
                              </Select>
                              <TextField
                                className="table-cell"
                                name="Specifications"
                                value={editableFields.Specifications !== undefined ? editableFields.Specifications.join(', ') : row.Specifications.join(', ')}
                                onChange={(e) => handleFieldChange(e, "Specifications")}
                                placeholder="Enter specifications"
                                variant="outlined"
                                multiline
                                rows={2}
                                style={{ marginTop: '8px', width: '100%' }} // Adjust as needed
                              />
                            </>
                          ) : (
                            <Tooltip
                              title={row.Specifications.join(', ') || 'No specifications available'}
                              placement="top"
                              PopperProps={{
                                sx: {
                                  '.MuiTooltip-tooltip': {
                                    maxWidth: '300px',
                                    fontSize: '1.2rem',
                                    padding: '10px 15px',
                                  },
                                },
                              }}
                            >
                              <span style={{ cursor: 'pointer', display: 'inline-block' }}>
                                {row.MakeList}
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </>
                  )}

                  {allowedColumns.includes("Person") && (
                    <>
                      {visibleColumns.Person && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField
                              className="text-field table-cell"
                              value={editableFields.Person !== undefined ? editableFields.Person : row.Person}
                              onChange={(e) => handleFieldChange(e, "Person")}
                            />
                          ) : (
                            row.Person
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("Amount") && (
                    <>
                      {visibleColumns.Amount && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField
                              className="text-field table-cell"
                              type="text"
                              value={editableFields.Amount !== undefined ? editableFields.Amount : row.amount}
                              onChange={(e) => handleFieldChange(e, "Amount")}
                            />
                          ) : (
                            row.Amount
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("Status") && (
                    <>
                      {visibleColumns.Status && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <Select
                              className="select-field"
                              value={editableFields.Status !== undefined ? editableFields.Status : row.status}
                              onChange={(e) => handleFieldChange(e, "Status")}
                            >
                              <MenuItem value="" disabled>
                                {" "}
                                Select Status{" "}
                              </MenuItem>
                              <MenuItem value="N/A">N/A</MenuItem>
                              <MenuItem value="Transferd">Transferd</MenuItem>
                              <MenuItem value="Pendig">Pending</MenuItem>
                            </Select>
                          ) : (
                            row.Status
                          )}
                        </TableCell>
                      )}
                    </>
                  )}
                  {allowedColumns.includes("Comment") && (
                    <>
                      {visibleColumns.Comment && (
                        <TableCell className="table-cell">
                          {editRowId === row._id ? (
                            <TextField
                              className="table-cell"
                              name="Comment"
                              multiline
                              rows={4} // Allows user to see multiple lines
                              value={editableFields.Comment !== undefined ? editableFields.Comment : row.Comment}
                              onChange={(e) => handleFieldChange(e, "Comment")}
                            />
                          ) : (
                            <Tooltip
                              title={row.Comment || "No specifications available"}
                              placement="top"
                              PopperProps={{
                                sx: {
                                  ".MuiTooltip-tooltip": {
                                    maxWidth: "300px",
                                    fontSize: "1.2rem",
                                    padding: "10px 15px",
                                  },
                                },
                              }}
                            >
                              <span
                                className="comment-text"
                                style={{
                                  display: "inline-block",
                                  maxWidth: "200px", // Adjust as needed
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {row.Comment || "No specifications available"}
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </>
                  )}

                  <TableCell className="table-cell">
                    {(row.CreatedBy === userId || userRole === 'administrator') ? (editRowId === row._id ? (
                      <>
                        <Button onClick={handleSave}>Save</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => handleEditClick(row._id)}>
                          Edit
                        </Button>
                        <Button onClick={() => handleDelete(row._id)}>
                          Delete
                        </Button>
                      </>
                    )
                    ) : (
                      <span></span> // Optional: You can show a message or just leave it empty
                    )}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPages > 1 && (
          <div className="pagination-controls">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1} // Disable if already on the first page
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages} // Disable if already on the last page
            >
              Next
            </button>
          </div>
        )}

      </>
    </div>
  );
};

export default ProjectTable;