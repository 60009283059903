import React, { useState, useEffect, useCallback } from "react";
import {
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, Button, MenuItem, Select
} from "@mui/material";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import { format } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ResizableBox } from "react-resizable";
import { Link } from "react-router-dom";
import "react-resizable/css/styles.css";
import socket from '../../socket'
import AddNewService from "./AddNewService";
import './ServiceTrackerTable.css'

const COLUMN_WIDTHS_KEY = "tableColumnWidths";
const getStoredColumnWidths = () => JSON.parse(localStorage.getItem(COLUMN_WIDTHS_KEY)) || [200, 200, 200];
const storeColumnWidths = (widths) => localStorage.setItem(COLUMN_WIDTHS_KEY, JSON.stringify(widths));
const ResizableTableCell = ({ width, onResize, children }) => (
  <ResizableBox
    width={120}
    height={0}
    axis="x"
    resizeHandles={["e"]}
    onResize={onResize}
    style={{ display: "flex", alignItems: "center", minWidth: '120px', minHeight: '10px', color: 'white' }}
  >
    <div style={{ width: "100%" }}>{children}</div>
  </ResizableBox>
);

const ServiceTrackerTable = () => {
  const [columnWidths, setColumnWidths] = useState(getStoredColumnWidths())
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const getInitialLimit = () => {
    const savedLimit = localStorage.getItem("itemsPerPage");
    return savedLimit ? Number(savedLimit) : 10; // Default to 10 if not found
  };

  const [limit, setLimit] = useState(getInitialLimit());
  const [filters, setFilters] = useState({
    modelNumber: "",
    serviceType: "",
    projectName: "",
    location: "",
    userName: "",
    serviceMode: "",
  });
  const [user, setUser] = useState("");
  const [editRowId, setEditRowId] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null, // the column name
    direction: "asc", // asc or desc
  });

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
  };

  const sortedRows = [...filteredRows].sort((a, b) => {
    if (sortConfig.key) {
      let aVal = a[sortConfig.key];
      let bVal = b[sortConfig.key];

      if (sortConfig.key.includes("Date")) {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      } else if (!isNaN(aVal) && !isNaN(bVal)) {
        aVal = parseFloat(aVal);
        bVal = parseFloat(bVal);
      }
      if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleResize = (index) => (e) => {
    e.preventDefault();
    const startX = e.clientX;

    const onMouseMove = (moveEvent) => {
      const newWidth = Math.max(columnWidths[index] + (moveEvent.clientX - startX), 50); // Prevent negative width
      const newWidths = [...columnWidths];
      newWidths[index] = newWidth;
      setColumnWidths(newWidths);
    };

    const onMouseUp = () => {
      storeColumnWidths(columnWidths); // Update localStorage on mouse up
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const userRole = user.Role;

  const userId = user._id;

  const applyFilters = useCallback((service) => {
    const { modelNumber = "", serviceType = "", projectName = "", location = "", userName = "", serviceMode = "" } = filters;

    const modelNumberLower = modelNumber.toLowerCase();
    const serviceTypeLower = serviceType.toLowerCase();
    const projectNameLower = projectName.toLowerCase();
    const locationLower = location.toLowerCase();
    const userNameLower = userName.toLowerCase();
    const serviceModeLower = serviceMode.toLowerCase();

    const matchesFilters =
      (!modelNumberLower || service.ModelNumber?.toLowerCase()?.includes(modelNumberLower)) &&
      (!serviceTypeLower || service.ServiceType?.toLowerCase()?.startsWith(serviceTypeLower)) &&
      (!projectNameLower || service.ProjectName?.toLowerCase()?.startsWith(projectNameLower)) &&
      (!locationLower || service.Location?.toLowerCase()?.startsWith(locationLower)) &&
      (!userNameLower || service.UserName?.toLowerCase()?.includes(userNameLower)) &&
      (!serviceModeLower || service.ServiceMode?.toLowerCase()?.startsWith(serviceModeLower));

    return matchesFilters;
  }, [filters]);


  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}projectusers`
      );
      const currentUser = response.data.allUsers.find(
        (u) => u._id === getUserIdFromUrl()
      );
      setUser(currentUser);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        ...filters,
        page: filtersApplied() ? 1 : currentPage,
        limit,
        userId: getUserIdFromUrl(),
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}show-service`,
        {
          params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const projects = response.data?.services || []; // Ensure projects is an array

      setRows(projects);
      setFilteredRows(projects.filter(applyFilters)); // Apply filters to fetched data
      setTotalPages(response.data?.totalPages || 0); // Default to 0 if undefined
      if (filtersApplied()) setCurrentPage(1); // Reset the current page
    } catch (error) {
      console.error("Error fetching data:", error.response?.data || error);
    } finally {
      setIsLoading(false);
    }
  }, [filters, currentPage, limit, applyFilters]);



  const filtersApplied = () => {
    return Object.values(filters).some((value) => value.trim() !== "");
  };

  useEffect(() => {
    fetchData();

    socket.on('update_data', (newProjects) => {
      fetchData(); // Refresh the data
    });

    return () => {
      socket.off('update_data');
    };
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLimitChange = (event) => {
    const newLimit = Number(event.target.value);
    setLimit(newLimit); // Update the limit state
    localStorage.setItem("itemsPerPage", newLimit); // Store the new limit in localStorage
    setCurrentPage(1); // Reset to the first page whenever the limit changes
  };

  const getUserIdFromUrl = () => {
    const url = window.location.href;
    return url.substring(url.lastIndexOf("/") + 1);
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [page, filters, user, fetchData]);

  useEffect(() => {
    if (Array.isArray(rows)) {
      setFilteredRows(rows.filter(applyFilters));
    }
  }, [rows, filters, applyFilters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setPage(1);
  };
  const handleDelete = async (id) => {
    try {
      const isConfirmed = window.confirm("Do you really want to delete this project?");
      if (!isConfirmed) {
        return; // Exit if the user cancels
      }

      await axios.delete(
        `${process.env.REACT_APP_API_URL}delete-service/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setRows((prevRows) => prevRows.filter((row) => row._id !== id));
      toast.success("Project deleted successfully");
    } catch (error) {
      toast.error("Error deleting project");
    }
  };

  const handleEditClick = (id) => {
    setEditRowId(id);
    const rowToEdit = rows.find((row) => row._id === id);

    const formatDateForInput = (date) => {
      if (!date) return ""; // Handle null or undefined dates
      const parsedDate = new Date(date); // Parse the date string to a Date object
      return parsedDate.toISOString().split("T")[0]; // Return the date in YYYY-MM-DD format
    };

    setEditableFields({
      ServiceType: rowToEdit.ServiceType,
      Comment: rowToEdit.Comment,
      ProjectName: rowToEdit.ProjectName,
      CustomerName: rowToEdit.CustomerName,
      Location: rowToEdit.Location,
      ServiceMode: rowToEdit.ServiceMode,
      ModelNumber: rowToEdit.ModelNumber,
      Issue: rowToEdit.Issue,
      Warranty: rowToEdit.Warranty,
      Specifications: rowToEdit.Specifications, // Ensure this is included
      CloseDate: formatDateForInput(rowToEdit.CloseDate),
      OpenDate: formatDateForInput(rowToEdit.OpenDate),
      Status: rowToEdit.Status,
      createdAt: new Date().toISOString(),
    });

  };

  const handleFieldChange = (event, fieldName) => {
    const { value } = event.target;
    if (fieldName === "Specifications") {
      const specificationsArray = value.split(',').map(spec => spec.trim());
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: specificationsArray, // Update with the array
      }));
    } else {
      setEditableFields((prevFields) => ({
        ...prevFields,
        [fieldName]: value,
      }));
    }
  };

  const handleSave = async () => {
    if (!editRowId) return;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}update-service/${editRowId}`,
        editableFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      const updatedProject = response.data.updatedProject;
      console.log(response.data)
      setRows((prevRows) =>
        prevRows.map((row) =>
          row._id === editRowId ? { ...row, ...updatedProject } : row
        )
      );
      setFilteredRows((prevFilteredRows) =>
        prevFilteredRows.map((row) =>
          row._id === editRowId ? { ...row, ...updatedProject } : row
        )
      );


      socket.emit('projectUpdated', updatedProject);

      fetchData(); // Fetch updated data from the server

      setEditRowId(null);
      setEditableFields({});
      toast.success("Project updated successfully");
    } catch (error) {
      toast.error("Error updating project");
      console.error("Error updating project:", error);
    }
  };

  useEffect(() => {
    const handleProjectUpdate = (updatedProject) => {

      setRows((prevRows) =>
        prevRows.map((row) => (row._id === updatedProject._id ? { ...row, ...updatedProject } : row))
      );
      setFilteredRows((prevFilteredRows) =>
        prevFilteredRows.map((row) => (row._id === updatedProject._id ? { ...row, ...updatedProject } : row))
      );
    };

    socket.on('projectUpdated', handleProjectUpdate);
    return () => {
      socket.off('projectUpdated', handleProjectUpdate);
    };
  }, []);

  useEffect(() => {
    fetchData(); // Fetch data when component mounts
  }, [fetchData]);

  const handleCancel = () => {
    setEditRowId(null);
    setEditableFields({});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy");
  };

  const resetFilters = () => {
    setFilters({
      modelNumber: "",
      serviceType: "",
      projectName: "",
      location: "",
      userName: "",
      serviceMode: "",
    });
    setPage(1);
    fetchData(); // Call fetchData to refresh the data based on reset filters
  };

  const handleAddEmployee = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleBulkDelete = async () => {
    try {
      const isConfirmed = window.confirm("Do you really want to delete the selected projects?");
      if (!isConfirmed) {
        return; // Exit if the user cancels
      }

      await Promise.all(
        bulkSelectedIds.map((id) =>
          axios.delete(`${process.env.REACT_APP_API_URL}delete-service/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
        )
      );
      setRows((prev) => prev.filter((service) => !bulkSelectedIds.includes(service._id)));
      setBulkSelectedIds([]); // Reset selection after deletion
      toast.success("Selected projects deleted successfully");
    } catch (error) {
      console.error("Error deleting selected projects:", error);
      toast.error("Error deleting selected projects");
    }
  };
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const handleArchiveSelected = async () => {
    try {
      if (bulkSelectedIds.length === 0) {
        toast.error("No projects selected for archiving");
        return;
      }

      const isConfirmed = window.confirm("Do you really want to archive the selected projects?");
      if (!isConfirmed) {
        return; // Exit if the user cancels
      }

      await Promise.all(
        bulkSelectedIds.map((id) =>
          axios.post(
            `${API_BASE_URL}archive-service/${id}`,
            { userId }, // Ensure userId is correctly passed
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          )
        )
      );

      // Remove archived services from the UI after a successful request
      setRows((prev) => prev.filter((service) => !bulkSelectedIds.includes(service._id)));

      setBulkSelectedIds([]); // Reset selection after archiving
      toast.success("Selected projects archived successfully");
    } catch (err) {
      console.error("Error archiving selected projects:", err);
      toast.error("Error archiving selected projects");
    }
  };


  return (
    <div className="show-project-wrap">
      <div className="top-buttons">
        <div className="add-project-section">
          <div className="add-project-btn" style={{ cursor: "pointer" }} onClick={() => handleAddEmployee()} >
            <span> + Add Service </span>
          </div>
          {showPopup && (
            <div className="popup-overlay">
              <div className="add-project-popup">
                <AddNewService onClose={handleClosePopup} />
              </div>
            </div>
          )}
        </div>
        <div className="import-export-box">
          <Button onClick={() => setIsOpen(prev => !prev)}>
            {isOpen ? 'Hide Filters' : 'Show Filters'}
          </Button>
          <Button><Link to='/archive-services'>Show Archived</Link></Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleArchiveSelected}
            disabled={bulkSelectedIds.length === 0}
          >
            Archive Selected
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkDelete}
            disabled={bulkSelectedIds.length === 0}
          >
            Delete Selected
          </Button>
        </div>
      </div >
      <>
        <div className="project-table">
          <div className="filter-row">
          </div>

          <div className="input-filters">
            {isOpen && (
              <div className="filters-content open">
                <input type="text" placeholder="User Name" name="userName" value={filters.userName} onChange={handleFilterChange} />

                <input type="text" placeholder="Project Name" name="projectName" value={filters.projectName} onChange={handleFilterChange} />

                <input type="text" placeholder="Service Type" name="serviceType" value={filters.serviceType} onChange={handleFilterChange} />

                <input type="text" placeholder="ModelNumber" name="modelNumber" value={filters.modelNumber} onChange={handleFilterChange} />

                <input type="text" placeholder="Location" name="location" value={filters.location} onChange={handleFilterChange} />

                <input type="text" placeholder="Service Mode" name="serviceMode" value={filters.serviceMode}
                  onChange={handleFilterChange} />

                <button onClick={resetFilters}>Reset</button>
              </div>
            )}
          </div>
        </div>

        <TableContainer className="project-grid" component={Paper}>
          <div className="project-range-box">
            <label htmlFor="limit">Items per page:</label>
            <select id="limit" value={limit} onChange={handleLimitChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </select>
          </div>

          <Table className="table-container">
            <TableHead className="table-header">
              <TableRow className="table-row">

                <TableCell className="check-box table-cell" style={{ width: columnWidths[0], border: '1px solid black', fontWeight: '600' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setBulkSelectedIds(checked ? rows.map((row) => row._id) : []);
                    }}
                  />
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("UserName")} >
                  <ResizableTableCell width={columnWidths[0]} onResize={(e, data) => handleResize(0, e, data)} >
                    User Name {sortConfig.key === "UserName" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("OpenDate")} >
                  <ResizableTableCell width={columnWidths[1]} onResize={(e, data) => handleResize(0, e, data)} >Opening Date {sortConfig.key === "OpenDate" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("ProjectName")} >
                  <ResizableTableCell width={columnWidths[2]} onResize={(e, data) => handleResize(0, e, data)} >
                    Project Name {sortConfig.key === "ProjectName" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("CustomerName")} >
                  <ResizableTableCell width={columnWidths[3]} onResize={(e, data) => handleResize(0, e, data)} >
                    Customer Name {sortConfig.key === "CustomerName" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("Location")} >
                  <ResizableTableCell width={columnWidths[4]} onResize={(e, data) => handleResize(0, e, data)} >
                    Location {sortConfig.key === "Location" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("ServiceMode")} >
                  <ResizableTableCell width={columnWidths[5]} onResize={(e, data) => handleResize(0, e, data)} >
                    ServiceMode {sortConfig.key === "ServiceMode" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("ModelNumber")} >
                  <ResizableTableCell width={columnWidths[6]} onResize={(e, data) => handleResize(0, e, data)} >
                    ModelNumber {sortConfig.key === "ModelNumber" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("Issue")} >
                  <ResizableTableCell width={columnWidths[7]} onResize={(e, data) => handleResize(0, e, data)} >
                    Issue {sortConfig.key === "Issue" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("Warranty")} >
                  <ResizableTableCell width={columnWidths[8]} onResize={(e, data) => handleResize(0, e, data)} >
                    Warranty {sortConfig.key === "Warranty" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>


                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("ServiceType")} >
                  <ResizableTableCell width={columnWidths[9]} onResize={(e, data) => handleResize(0, e, data)} >
                    Service Type {sortConfig.key === "ServiceType" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell"
                  style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }}
                  onClick={() => handleSort("CloseDate")}
                >
                  <ResizableTableCell width={columnWidths[10]} onResize={(e, data) => handleResize(0, e, data)} >
                    CloseDate {sortConfig.key === "CloseDate" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600", cursor: "pointer" }} onClick={() => handleSort("Comment")} >
                  <ResizableTableCell width={columnWidths[11]} onResize={(e, data) => handleResize(0, e, data)} >
                    Comment {sortConfig.key === "Comment" && (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </ResizableTableCell>
                </TableCell>

                <TableCell className="table-cell" style={{ width: "5%", border: "1px solid black", fontWeight: "600" }}>Actions</TableCell>
              </TableRow>
            </TableHead>


            <TableBody style={{ border: "1px solid black" }}>
              {sortedRows.map((row) => (
                <TableRow key={row._id} style={{ border: "1px solid black", backgroundColor: !row.CloseDate ? "#ff7a83" : "#7aff95", }} >
                  <TableCell className="check-box" style={{ width: columnWidths[0], border: '1px solid black', fontWeight: '600' }}>
                    <input
                      type="checkbox"
                      checked={bulkSelectedIds.includes(row._id)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setBulkSelectedIds((prev) =>
                          checked ? [...prev, row._id] : prev.filter((id) => id !== row._id)
                        );
                      }}
                    />
                  </TableCell>

                  <TableCell className="table-cell" style={{ fontSize: "15px", textTransform: "capitalize" }}>
                    <strong>
                      {row.CreatedBy ? `${row.CreatedBy.FirstName} ${row.CreatedBy.LastName}` : "N/A"}
                    </strong>
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <TextField
                        className="table-cell"
                        name="OpenDate"
                        type="date"
                        value={
                          editableFields.OpenDate !== undefined
                            ? editableFields.OpenDate // Use value from editableFields
                            : row.OpenDate
                              ? row.OpenDate.split("T")[0] // Use row value if editableFields is undefined
                              : ""
                        }
                        onChange={(e) => handleFieldChange(e, "OpenDate")}
                      />
                    ) : row.OpenDate ? (
                      formatDate(row.OpenDate) // Display formatted date
                    ) : (
                      "dd-mm-yyyy" // Placeholder for empty date
                    )}
                  </TableCell>

                  <TableCell className="table-cell" >
                    {editRowId === row._id ? (
                      <TextField className="table-cell"
                        name="ProjectName"
                        value={
                          editableFields.ProjectName !== undefined
                            ? editableFields.ProjectName
                            : row.ProjectName
                        }
                        onChange={(e) => handleFieldChange(e, "ProjectName")}
                      />
                    ) : (
                      row.ProjectName
                    )}
                  </TableCell>

                  <TableCell className="table-cell" >
                    {editRowId === row._id ? (
                      <TextField className="table-cell"
                        name="CustomerName"
                        value={
                          editableFields.CustomerName !== undefined
                            ? editableFields.CustomerName
                            : row.CustomerName
                        }
                        onChange={(e) => handleFieldChange(e, "CustomerName")}
                      />
                    ) : (
                      row.CustomerName
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <TextField className="table-cell"
                        name="Location"
                        value={
                          editableFields.Location !== undefined
                            ? editableFields.Location
                            : row.Location
                        }
                        onChange={(e) => handleFieldChange(e, "Location")}
                      />
                    ) : (
                      row.Location
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <>
                        <Select
                          className="table-cell"
                          name="ServiceMode"
                          value={editableFields.ServiceMode !== undefined ? editableFields.ServiceMode : row.ServiceMode}
                          onChange={(e) => handleFieldChange(e, "ServiceMode")}
                        >
                          <MenuItem value="" disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="Technical Call">Technical Call</MenuItem>
                          <MenuItem value="Site Visit">Site Visit</MenuItem>
                          <MenuItem value="In Houe">In Houe</MenuItem>
                        </Select>
                        <TextField
                          className="table-cell"
                          name="Specifications"
                          value={editableFields.Specifications !== undefined ? editableFields.Specifications.join(', ') : row.Specifications.join(', ')}
                          onChange={(e) => handleFieldChange(e, "Specifications")}
                          placeholder="Enter specifications"
                          variant="outlined"
                          multiline
                          rows={2}
                          style={{ marginTop: '8px', width: '100%' }} // Adjust as needed
                        />
                      </>
                    ) : (
                      <Tooltip
                        title={row.Specifications.join(', ') || 'No specifications available'}
                        placement="top"
                        PopperProps={{
                          sx: {
                            '.MuiTooltip-tooltip': {
                              maxWidth: '300px',
                              fontSize: '1.2rem',
                              padding: '10px 15px',
                            },
                          },
                        }}
                      >
                        <span style={{ cursor: 'pointer', display: 'inline-block' }}>
                          {row.ServiceMode}
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <TextField className="table-cell"
                        name="ModelNumber"
                        value={
                          editableFields.ModelNumber !== undefined
                            ? editableFields.ModelNumber
                            : row.ModelNumber
                        }
                        onChange={(e) => handleFieldChange(e, "ModelNumber")}
                      />
                    ) : (
                      row.ModelNumber
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <TextField className="table-cell"
                        name="Issue"
                        value={
                          editableFields.Issue !== undefined
                            ? editableFields.Issue
                            : row.Issue
                        }
                        onChange={(e) => handleFieldChange(e, "Issue")}
                      />
                    ) : (
                      row.Issue
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <Select
                        className="select-field table-cell"
                        name="Warranty"
                        value={
                          editableFields.Warranty !== undefined ? editableFields.Warranty : row.Warranty
                        }
                        onChange={(e) =>
                          handleFieldChange(e, "Warranty")
                        }
                      >
                        <MenuItem value="" disabled>
                          Select Warranty
                        </MenuItem>
                        <MenuItem value="In Warranty">In Warranty</MenuItem>
                        <MenuItem value="Expired">Expired</MenuItem>
                      </Select>
                    ) : (
                      row.Warranty
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <Select
                        className="select-field table-cell"
                        name="ServiceType"
                        value={
                          editableFields.ServiceType !== undefined ? editableFields.ServiceType : row.ServiceType
                        }
                        onChange={(e) =>
                          handleFieldChange(e, "ServiceType")
                        }
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="Free">Free</MenuItem>
                      </Select>
                    ) : (
                      row.ServiceType
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <TextField
                        className="table-cell"
                        name="CloseDate"
                        type="date"
                        value={
                          editableFields.CloseDate !== undefined
                            ? editableFields.CloseDate // Use value from editableFields
                            : row.CloseDate
                              ? row.CloseDate.split("T")[0] // Use row value if editableFields is undefined
                              : ""
                        }
                        onChange={(e) => handleFieldChange(e, "CloseDate")}
                      />
                    ) : row.CloseDate ? (
                      formatDate(row.CloseDate) // Display formatted date
                    ) : (
                      "dd-mm-yyyy" // Placeholder for empty date
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {editRowId === row._id ? (
                      <TextField
                        className="table-cell"
                        name="Comment"
                        multiline
                        rows={4} // Allows user to see multiple lines
                        value={editableFields.Comment !== undefined ? editableFields.Comment : row.Comment}
                        onChange={(e) => handleFieldChange(e, "Comment")}
                      />
                    ) : (
                      <Tooltip
                        title={row.Comment || "No specifications available"}
                        placement="top"
                        PopperProps={{
                          sx: {
                            ".MuiTooltip-tooltip": {
                              maxWidth: "300px",
                              fontSize: "1.2rem",
                              padding: "10px 15px",
                            },
                          },
                        }}
                      >
                        <span
                          className="comment-text"
                          style={{
                            display: "inline-block",
                            maxWidth: "200px", // Adjust as needed
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {row.Comment || "No specifications available"}
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className="table-cell">
                    {(row.CreatedBy._id === userId || userRole === 'administrator') ? (editRowId === row._id ? (
                      <>
                        <Button onClick={handleSave}>Save</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => handleEditClick(row._id)}>
                          Edit
                        </Button>
                        <Button onClick={() => handleDelete(row._id)}>
                          Delete
                        </Button>
                      </>
                    )
                    ) : (
                      <span></span> // Optional: You can show a message or just leave it empty
                    )}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPages > 1 && (
          <div className="pagination-controls">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1} // Disable if already on the first page
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages} // Disable if already on the last page
            >
              Next
            </button>
          </div>
        )}

      </>
    </div>
  );
};

export default ServiceTrackerTable;