import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../../App';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../Assets/Images/logo.png';
import './login.css';

function Login() {
  const { dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: identifier.toLowerCase(),
          password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === "User is disabled") {
          toast.error("Your account is disabled. Contact the administrator.");
        } else {
          toast.error(errorData.message || "Invalid ID or password.");
        }
        return;
      }

      const data = await response.json();

      if (data.error) {
        toast.error(data.error);
      } else {
        localStorage.setItem('jwt', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        dispatch({ type: "USER", payload: data.user });
        toast.success("Login successful!");
        setTimeout(() => navigate('/'), 2000); // Navigate after showing success toast
      }
    } catch (error) {
      console.log("Login Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className='login-wrap'>
      <div className="login-left"></div>
      <div className="login-right">
        <div className="login-form">
          <div className="login-logo">
            <img src={Logo} alt="Logo" />
          </div>

          <div className="logiemail-input">
            <input
              type="text"
              placeholder="Email or Username"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value.toLowerCase())}
            />
          </div>
          <div className="logipass-input">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className='show-hide'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </span>
          </div>
          <button onClick={handleLogin}>Login</button>
          <Link to='/request-reset'>
            <span>Forgot Password?</span>
          </Link>
        </div>
      </div>

      {/* Toast notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Login;
