import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../App";
import "./profileDropdown.css";
import { Link, useNavigate } from "react-router-dom";
import ProfilePreview from '../../Assets/Images/profile-prev.png'
import axios from "axios";
import { toast } from "react-toastify";

const ProfileDropdown = ({ isCollapsed }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(UserContext);
    const [profileData, setProfileData] = useState(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user._id : null;

  useEffect(() => {
    const fetchProfileData = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}user-profile/${userId}`);
          setProfileData(response.data);
        } catch (error) {
          console.error("Error fetching profile data:", error);
          toast.error('Failed to fetch profile data!');
        }
      }
    };

    fetchProfileData();
  }, [userId]);

  const handleLogout = async () => {
    try {
      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      console.log("Logout Error:", error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userName =
    state.user ? `${state.user.FirstName} ${state.user.LastName}` : "Guest";
    
  const profileImageUrl = profileData?.ProfileImageUrl || ProfilePreview;

  return (
    <div className="profile-dropdown" ref={dropdownRef} onClick={isCollapsed ? null : toggleDropdown}>
      <div className={`profile-header ${isCollapsed ? "collapsed" : ""}`}>
        <img src={profileImageUrl} alt="Profile" className="profile-picture" />
      </div>
      {!isCollapsed && <Link to={`/employee-profile/${state.user?._id}`}>
          <p className="profile-name" style={{fontWeight:"600", marginLeft:'10px'}}>{userName}</p>
          </Link>}
      {!isCollapsed && <div className="dropdown-triangle"></div>}
      {isOpen && !isCollapsed && (
        <ul className="dropdown-menu show">
          
          <Link to={`/employee-profile/${state.user?._id}`}>
            <li>Profile</li>
          </Link>
          <li onClick={handleLogout}>Logout</li>
        </ul>
      )}
    </div>
  );
};

export default ProfileDropdown;
