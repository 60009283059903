import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Snackbar,
  Alert,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

const ArchiveProject = () => {
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bulkSelectedIds, setBulkSelectedIds] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  // Get user ID from localStorage
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user ? user._id : null;

  // Fetch archived projects
  const fetchArchivedProjects = useCallback(async () => {
    if (!userId) {
      console.error('User ID is undefined');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}show-archived-service`, {
        params: { userId },
      });
      setArchivedProjects(response.data.service || []);
    } catch (err) {
      console.error('Error fetching archived projects:', err);
      setError(err.response?.data?.error || 'An error occurred while fetching archived projects.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Memoize columns, remove _id, createdAt, updatedAt, and _v
  const columns = useMemo(() => {
    if (archivedProjects.length > 0) {
      const filteredColumns = Object.keys(archivedProjects[0]).filter(
        (col) => !['_id', 'createdAt', 'updatedAt', '__v'].includes(col)
      );
      return ['CreatedBy', ...filteredColumns.filter((col) => col !== 'CreatedBy')];
    }
    return [];
  }, [archivedProjects]);

  // Format Date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date);
  };

  useEffect(() => {
    fetchArchivedProjects();
  }, [fetchArchivedProjects]);

  // Handle unarchiving a single project
  const handleUnarchive = async (projectId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}unarchive-service/${projectId}`, { userId });
      setArchivedProjects((prev) => prev.filter((project) => project._id !== projectId));
      setSuccessMessage('Project unarchived successfully!');
    } catch (err) {
      console.error('Error unarchiving project:', err);
      setError(err.response?.data?.error || 'An error occurred while unarchiving the project.');
    }
  };

  // Handle bulk unarchiving
  const handleBulkUnarchive = async () => {
    try {
      await Promise.all(
        bulkSelectedIds.map((id) =>
          axios.post(`${process.env.REACT_APP_API_URL}unarchive-service/${id}`, { userId })
        )
      );
      setArchivedProjects((prev) => prev.filter((project) => !bulkSelectedIds.includes(project._id)));
      setBulkSelectedIds([]);
      setSuccessMessage('Selected projects unarchived successfully!');
    } catch (err) {
      console.error('Error unarchiving selected projects:', err);
      setError(err.response?.data?.error || 'An error occurred while unarchiving the selected projects.');
    }
  };

  // Handle bulk selection change
  const handleBulkSelectChange = (e) => {
    setBulkSelectedIds(e.target.checked ? archivedProjects.map((row) => row._id) : []);
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (e, id) => {
    setBulkSelectedIds((prev) => (e.target.checked ? [...prev, id] : prev.filter((i) => i !== id)));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  // if (archivedProjects.length === 0) return <div>No archived projects found.</div>;

  return (
    <div>
      <Box display="flex" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBulkUnarchive}
          disabled={bulkSelectedIds.length === 0}
        >
          Unarchive Selected
        </Button>
        <Button>
          <Link to={`/service-tracker/${userId}`}>Show Active</Link>
        </Button>
      </Box>

      <TableContainer sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f4f6f8' }}>
              <TableCell sx={{ width: '5%' }}>
                <Checkbox
                  onChange={handleBulkSelectChange}
                  checked={bulkSelectedIds.length === archivedProjects.length && archivedProjects.length > 0}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  sx={{
                    fontWeight: '600',
                    color: '#3f51b5',
                    minWidth: '150px', // You can adjust the minWidth based on your content
                    maxWidth: column === 'CreatedBy' ? '200px' : '250px', // Adjust specific column widths
                    whiteSpace: 'nowrap', // Prevent text overflow
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {column}
                </TableCell>
              ))}
              <TableCell sx={{ width: '10%', fontWeight: '600', color: '#3f51b5' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {archivedProjects.map((row) => (
              <TableRow key={row._id} sx={{ '&:hover': { backgroundColor: '#e0f7fa' } }}>
                <TableCell sx={{ width: '5%' }}>
                  <Checkbox
                    checked={bulkSelectedIds.includes(row._id)}
                    onChange={(e) => handleCheckboxChange(e, row._id)}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    sx={{
                      minWidth: '150px',
                      maxWidth: column === 'CreatedBy' ? '200px' : '250px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {column === 'CreatedBy'
                      ? `${row[column]?.FirstName || 'N/A'} ${row[column]?.LastName || ''}`.trim()
                      : column.toLowerCase().includes('date')
                      ? formatDate(row[column])
                      : row[column] || 'N/A'}
                  </TableCell>
                ))}
                <TableCell sx={{ width: '10%' }}>
                  <Tooltip title="Unarchive">
                    <IconButton onClick={() => handleUnarchive(row._id)} color="primary">
                      <UnarchiveIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Success Snackbar */}
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ArchiveProject;
