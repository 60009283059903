import React from "react";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import "./profile-slider.css";

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

function maskPhone(phone) {
    if (!phone) return "NA";
    return phone.length > 4
        ? phone.slice(0, -3).replace(/\d/g, "x") + phone.slice(-3)
        : phone.replace(/\d/g, "*");
}

function maskEmail(email) {
    if (!email) return "NA";
    const [localPart, domain] = email.split("@");
    if (!domain) return email;
    const maskedLocalPart = localPart[0] + localPart.slice(1).replace(/./g, "x");
    return `${maskedLocalPart}@${domain}`;
}

function ProfileCard({ onClose, selectedUser, userDepartment, userRole, isOpen }) {
    return (
        <div className={`profile-preview ${isOpen ? "slide-in" : "slide-out"}`}>
            <div className="profile-data">
                <div className="profile-img">
                    <Avatar 
                        src={selectedUser?.ProfileImageUrl} 
                        alt={selectedUser?.FirstName} 
                        sx={{ width: 85, height: 85 }} 
                    />
                </div>
                <div className="profileLink">
                    {(userRole === "administrator" || userDepartment === "HR") && selectedUser?._id && ( 
                        <Link to={`/employee-profile/${selectedUser?._id}`}>
                            <span>Profile</span>
                        </Link>
                    )}
                </div>
                <div className="user-data">
                    <div className="profile-body">
                        <span className="user-name">{`${selectedUser?.FirstName} ${selectedUser?.LastName}`}</span>
                        <span className="user-designation">{selectedUser?.Designation || "NA"}</span>
                        <span className="user-dob">{selectedUser?.DOB ? formatDate(selectedUser.DOB) : "NA"}</span>
                        <span className="user-phone">{maskPhone(selectedUser?.Phone)}</span>
                        <span className="user-email">{maskEmail(selectedUser?.Email)}</span>
                        <span className="user-office-phone">{selectedUser?.OfficalPhone || "NA"}</span>
                        <span className="user-office-email">{selectedUser?.OfficeEmail || "NA"}</span>
                        <span className="user-location">{selectedUser?.Location || "NA"}</span>
                    </div>
                    <button className="close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default ProfileCard;
