import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Sidenav from "./component/Sidenav/Sidenav.js";
import EmployeesDB from "./pages/Employees/EmployeesDB.js";
import AddEmployee from "./component/AddEmployee/AddEmployee.js";
import { initialState, reducer } from "./Reducer/userReducer.js";
import ResetPassword from "./component/Login/ResetPassword.js";
import Login from "./component/Login/Login.js";
import RequestPassword from "./component/Login/RequestPassword.js";
import EmployeeProfile from "./component/EmployeeProfile/EmployeeProfile.js";
import PersonalDetails from "./component/EmployeeProfile/PersonalDetails.js";
import Notifications from "./component/Notification/Notifications.js";
import DashBoard from "./pages/DashBoard/DashBoard.js";
import Admin from "./pages/Admin/Admin.js";
import EditUser from "./component/EditUser/EditUser.js";
import ProjectTracker from "./pages/ProjectTracker/ProjectTracker.js";
import Attendance from "./pages/Attendance/Attendance.js";
import ProjectTable from "./pages/ProjectTracker/Showprojects.js";
import EmployeepermissionList from "./pages/Employees/EmployeepermissionList.js";
import Reports from "./component/Reports/Reports.js";
import "./App.css";
import Organization from "./pages/organization/organization.js";
import ArchiveProject from "./pages/ArchiveProjects/ArchivedProjects.js";
import ArchiveService from './pages/ArchiveProjects/ArchivedService.js'
import TaskManager from "./component/Task-manager/TaskManager.js";
import Spinner from "./component/Spinner/Spinner.js"; // Import Spinner
import { HolidayProvider } from "./pages/Attendance/HolidayContext.js";
import NotFoundPage from "./component/Notfound/NotFoundPage.js";
import ErrorBoundary from "./component/Errors/ErrorBoundary.js";
import ServiceTrackerTable from "./pages/ServiceTracker/ServiceTrackerTable.js";

export const UserContext = createContext();

const fetchUserDetails = async (dispatch) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("jwt");

  if (user && token) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}user-details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        dispatch({ type: "USER", payload: data });
      } else if (response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.error("Failed to fetch user details:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }
};

const Routing = ({ isCollapsed, toggleNav, setIsCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("jwt");

    if (user && token) {
      try {
        dispatch({ type: "USER", payload: JSON.parse(user) });
        fetchUserDetails(dispatch);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else if (
      !["/login", "/request-reset"].includes(location.pathname) &&
      !/^\/reset-password\/[^/]+$/.test(location.pathname)
    ) {
      navigate("/login");
    }

    setIsLoading(false);
  }, [dispatch, navigate, location.pathname]);

  const isLoggedIn = Boolean(state?.user);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <div className="home-wrap">
            {isLoggedIn && (
              <div
                className={`left ${isCollapsed ? "collapsed" : ""}`}
                onMouseEnter={() => setIsCollapsed(false)}
                onMouseLeave={() => setIsCollapsed(true)}
              >
                <Sidenav
                  userId={state?.user?._id}
                  userDepartment={state?.user?.Department}
                  userRole={state?.user?.Role}
                  isCollapsed={isCollapsed}
                  toggleNav={toggleNav}
                />
                <button className="toggle-btn" onClick={toggleNav}>
                  <i className={`fa-solid fa-${isCollapsed ? "xmark" : "bars"}`}></i>
                </button>
              </div>
            )}
            <div className={`right ${!isLoggedIn ? "full-width" : ""} ${isCollapsed ? "collapsed" : ""}`}>
              <Routes>
                {state?.user?.Role === "administrator" && (
                  <>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/addemployee" element={<AddEmployee />} />
                    <Route path="/my-tasks" element={<TaskManager />} />
                  </>
                )}
                <Route path="/edit-user/:id" element={<EditUser />} />
                <Route path="/employee-dashboard" element={<EmployeesDB />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/add-project/:id" element={<ProjectTracker />} />
                <Route path="/project-table/:id" element={<ProjectTable />} />
                <Route path="/request-reset" element={<RequestPassword />} />
                <Route path="/employee-profile/:id" element={<EmployeeProfile />} />
                <Route path="/assign-user" element={<EmployeepermissionList />} />
                <Route path="/project-users/:projectId" element={<ProjectTable />} />
                <Route path="/" element={<DashBoard />} />
                <Route path="/attendance" element={<HolidayProvider><Attendance /></HolidayProvider>} />
                <Route path="/reports/:id" element={<Reports />} />
                <Route path="/personal-details" element={<PersonalDetails />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/archive-projects" element={<ArchiveProject />} />
                <Route path="/archive-services" element={<ArchiveService/>} />
                <Route path="/organization" element={<Organization userRole={state?.user?.Role} userDepartment={state?.user?.Department} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/404-error" element={<NotFoundPage />} />
                <Route path='service-tracker/:id'  element={<ServiceTrackerTable/>}/>
              </Routes>
            </div>
          </div>
        </>
      )}
    </>
  );
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleNav = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="App">
      <ErrorBoundary>
        <UserContext.Provider value={{ state, dispatch }}>
          <Router>
            <Routing isCollapsed={isCollapsed} toggleNav={toggleNav} setIsCollapsed={setIsCollapsed} />
          </Router>
        </UserContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
